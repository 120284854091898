import s from './EnhancedTimekeeepersContainer.scss'
import { Cell, TimekeeperRow } from './types'
import { ReactNode, useMemo } from 'react'
import { formatDate } from './helpers'
import { AvatarList, ChatBubbleIcon, Tooltip } from 'simple-core-ui'
import { StatusPopover } from 'timekeepers/common'
import { DEFAULT_COLUMN_CONFIG, DISALLOW_SORTING } from './constants'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa6'

const commonStyle = { maxWidth: '400px', position: 'relative' }

const settings = window.serverContext?.get('data_for_react')
const hasDiversityData = settings?.collect_diversity_data

const determineStatusMode = (selectedTab: string, content: string, row: TimekeeperRow) => {
  if (selectedTab === 'pending') {
    return content === 'pending' ? 'buttons' : 'dropdown'
  }
  if (selectedTab === 'completed' && !row.is_rate_used) {
    return 'dropdown'
  }
  return 'readOnly'
}

const createColumn = (key: string, content: string, additionalProps = {}) => ({
  columnKey: key,
  content,
  isSortable: true,
  isFilterable: true,
  style: commonStyle,
  ...additionalProps
})

const renderFormattedDate = (cell: Cell) =>
  typeof cell.content === 'string' && cell.content && cell.content !== '---'
    ? formatDate(cell.content)
    : '---'

const renderLink = (
  cell: Cell,
  link?: string,
  clickCb?: (arg: unknown) => void,
  isNew?: boolean
) => {
  if (typeof cell.content === 'string' && cell.content !== '---') {
    return (
      <div className={s.timekeeperName}>
        {isNew ? <div className={s.badge}>New</div> : null}
        <a href={link} onClick={clickCb}>
          {cell.content}
        </a>
      </div>
    )
  } else return cell.content
}

const renderReason = (reason: string | null | undefined, rowId: number | string): ReactNode => {
  if (!reason || reason === '---') {
    return reason
  }

  return (
    <span id={`reason-${rowId}`} data-tip={reason}>
      <img src={ChatBubbleIcon} className={s.chatBubble} alt="Reason details" />
    </span>
  )
}

const fieldMapping = {
  race: 'Race',
  gender: 'Gender',
  lgbtq: 'LGBTQ+',
  veteran_status: 'Veteran Status',
  disability: 'Disability'
}

const renderDiversityTooltip = (row: TimekeeperRow) => {
  const diversityKeys = Object.keys(fieldMapping) as Array<keyof typeof fieldMapping>

  const allFieldsCompleted = diversityKeys.every(key => row[key] && row[key] !== '---')

  if (allFieldsCompleted) {
    return (
      <div>
        All diversity fields for this <br />
        timekeeper are completed.
      </div>
    )
  } else {
    const providedFields = diversityKeys
      .filter(key => row[key])
      .map(key => fieldMapping[key])
      .sort()

    const notProvidedFields = diversityKeys
      .filter(key => !row[key])
      .map(key => fieldMapping[key])
      .sort()

    if (!providedFields.length) {
      return (
        <div>
          Diversity fields for this <br /> timekeeper are empty.
        </div>
      )
    }

    return (
      <>
        <div>
          <b>Not provided:</b> {notProvidedFields.length ? notProvidedFields.join(', ') : 'None'}
        </div>
        <div>
          <b>Provided:</b> {providedFields.length ? providedFields.join(', ') : 'None'}
        </div>
      </>
    )
  }
}

export const useColumns = (
  selectedTab: string,
  isListView: boolean,
  handleClickCell: (type: string, row: TimekeeperRow) => void,
  handleTimekeeperClick: (row: TimekeeperRow) => void,
  isBatchDetails: boolean,
  handleApprove: (row?: TimekeeperRow, all?: boolean) => Promise<void>,
  handleReject: (row?: TimekeeperRow, all?: boolean) => void,
  handleReset: (row: TimekeeperRow) => Promise<void>
) => {
  return useMemo(() => {
    const columns = []

    if (isListView) {
      columns.push(
        createColumn('timekeeper_id', 'ID', {
          style: { width: '400px' },
          ...DEFAULT_COLUMN_CONFIG
        }),
        createColumn('timekeeper', 'Timekeeper', {
          render: (cell: Cell, row: TimekeeperRow) =>
            renderLink(
              cell,
              undefined,
              () => handleTimekeeperClick(row),
              row.is_new_rate || row.is_new_tk
            ),
          ...DEFAULT_COLUMN_CONFIG
        }),
        createColumn('vendor_name', 'Vendor', {
          render: (cell: Cell, row: TimekeeperRow) =>
            renderLink(cell, `/invoices/vendors/${row.vendor_id}/?fromTimekeepers=true`),
          ...DEFAULT_COLUMN_CONFIG
        }),
        createColumn('classification', 'Classification', DEFAULT_COLUMN_CONFIG),
        createColumn('approved', 'Approved Rates', {
          render: (cell: Cell) => <div className={s.approved}>{cell.content as string}</div>,
          ...DEFAULT_COLUMN_CONFIG
        }),
        createColumn('rejected', 'Rejected Rates', {
          render: (cell: Cell) => <div className={s.rejected}>{cell.content as string}</div>,
          ...DEFAULT_COLUMN_CONFIG
        }),
        createColumn('pending', 'Pending Rates', {
          render: (cell: Cell) => <div className={s.pending}>{cell.content as string}</div>,
          ...DEFAULT_COLUMN_CONFIG
        }),
        ...(hasDiversityData
          ? [
              createColumn('diversity', 'Diversity Filled', {
                render: (cell: Cell, row: TimekeeperRow) => (
                  <Tooltip
                    trigger={<div className={s.diversityCell}>{cell.content as string}</div>}
                    content={renderDiversityTooltip(row)}
                    placement="top"
                    contentClassName={s.diversityTooltip}
                    border
                    arrowStrokeColor="#D4D4D4"
                  />
                ),
                ...DEFAULT_COLUMN_CONFIG
              })
            ]
          : []),
        createColumn('modified_date', 'Last Updated', {
          render: renderFormattedDate,
          ...DEFAULT_COLUMN_CONFIG
        }),
        createColumn('practice_group', 'Practice Group', DISALLOW_SORTING),
        createColumn('bar_year', 'Bar Year', DISALLOW_SORTING),
        createColumn('race', 'Race', DISALLOW_SORTING),
        createColumn('gender', 'Gender', DISALLOW_SORTING),
        createColumn('veteran_status', 'Veteran Status', DISALLOW_SORTING),
        createColumn('disability', 'Disability', DISALLOW_SORTING),
        createColumn('lgbtq', 'LGBTQ+', DISALLOW_SORTING),
        createColumn('country', 'Country', DISALLOW_SORTING),
        createColumn('location', 'Location', DISALLOW_SORTING)
      )
    } else {
      const allTabColumns =
        selectedTab === 'all' || isBatchDetails
          ? [
              ...(!isBatchDetails ? [createColumn('id', 'ID')] : []),
              createColumn('timekeeper', 'Timekeeper', {
                render: (cell: Cell, row: TimekeeperRow) =>
                  renderLink(
                    cell,
                    undefined,
                    () => handleTimekeeperClick(row),
                    row.is_new_rate || row.is_new_tk
                  ),
                ...DEFAULT_COLUMN_CONFIG
              }),
              ...(isBatchDetails
                ? [createColumn('id', 'ID')]
                : [
                    createColumn('vendor_name', 'Vendor', {
                      render: (cell: Cell, row: TimekeeperRow) =>
                        renderLink(
                          cell,
                          `/invoices/vendors/${row.vendor_id}/?fromTimekeepers=true`
                        ),
                      ...DEFAULT_COLUMN_CONFIG
                    })
                  ]),
              createColumn('classification', 'Classification', DEFAULT_COLUMN_CONFIG),
              createColumn('requested_rate', 'Billed Rate', DEFAULT_COLUMN_CONFIG),
              createColumn('rack_rate', 'Rack Rate', {
                render: (cell: Cell) => cell.content || '---',
                ...DEFAULT_COLUMN_CONFIG
              }),
              createColumn(
                'currency',
                `${isBatchDetails ? '' : 'Rate '}Currency`,
                DEFAULT_COLUMN_CONFIG
              ),
              ...(!isBatchDetails
                ? [
                    createColumn('status', 'Status', {
                      render: (cell: { content: string }) => (
                        <StatusPopover
                          mode="readOnly"
                          status={cell.content}
                          handleApprove={handleApprove}
                          handleReject={handleReject}
                          handleReset={handleReset}
                        />
                      )
                    })
                  ]
                : []),
              createColumn('effective', 'Effective Date', {
                render: renderFormattedDate,
                ...DEFAULT_COLUMN_CONFIG
              }),
              ...(!isBatchDetails
                ? [
                    createColumn('rejection_reason', 'Rejection Reason', {
                      render: (cell: { content: string }, row: TimekeeperRow) =>
                        renderReason(cell.content, row.id),
                      style: {
                        width: '150px',
                        textAlign: 'center'
                      }
                    })
                  ]
                : [createColumn('type', 'Type', DEFAULT_COLUMN_CONFIG)]),
              createColumn('reason_for_increase', 'Request Reason', {
                render: (cell: { content: string }, row: TimekeeperRow) =>
                  renderReason(cell.content, row.id),
                style: {
                  width: '150px',
                  textAlign: 'center'
                },
                ...DEFAULT_COLUMN_CONFIG
              }),
              ...(isBatchDetails
                ? [
                    createColumn('status', '', {
                      render: (cell: Cell, row: TimekeeperRow) => {
                        return (
                          <StatusPopover
                            mode={determineStatusMode(selectedTab, cell.content as string, row)}
                            status={cell.content as string}
                            handleApprove={handleApprove}
                            handleReject={handleReject}
                            handleReset={handleReset}
                            tableRow={row}
                          />
                        )
                      },
                      isSortable: false,
                      ...(selectedTab === 'completed' ? { style: { float: 'right' } } : {})
                    })
                  ]
                : []),
              ...(hasDiversityData
                ? [
                    createColumn('diversity', 'Diversity Filled', {
                      render: (cell: Cell, row: TimekeeperRow) => (
                        <Tooltip
                          trigger={<div className={s.diversityCell}>{cell.content as string}</div>}
                          content={renderDiversityTooltip(row)}
                          placement="top"
                        />
                      )
                    })
                  ]
                : []),
              createColumn('class_comparison', 'Class Comparison', {
                render: (cell: Cell) => {
                  if (!cell.content) return '---'
                  const isNegative = (cell.content as string).startsWith('-')
                  const isPositive = !isNegative && cell.content !== '0'
                  const displayValue = isNegative ? (cell.content as string).slice(1) : cell.content
                  return (
                    <>
                      {isNegative ? (
                        <FaArrowDown className={s.arrowDown} />
                      ) : isPositive ? (
                        <FaArrowUp className={s.arrowUp} />
                      ) : (
                        ''
                      )}
                      {displayValue}%
                    </>
                  )
                }
              }),
              createColumn('percentage_change', 'Percentage Change', {
                render: (cell: Cell) => {
                  if (!cell.content) return '---'
                  const isNegative = (cell.content as string).startsWith('-')
                  const isPositive = !isNegative && cell.content !== '0'
                  const displayValue = isNegative ? (cell.content as string).slice(1) : cell.content
                  return (
                    <>
                      {isNegative ? (
                        <FaArrowDown className={s.arrowDown} />
                      ) : isPositive ? (
                        <FaArrowUp className={s.arrowUp} />
                      ) : (
                        ''
                      )}
                      {displayValue}%
                    </>
                  )
                }
              }),
              createColumn('practice_group', 'Practice Group', DISALLOW_SORTING),
              createColumn('bar_year', 'Bar Year', DISALLOW_SORTING),
              createColumn('race', 'Race', DISALLOW_SORTING),
              createColumn('gender', 'Gender', DISALLOW_SORTING),
              createColumn('veteran_status', 'Veteran Status', DISALLOW_SORTING),
              createColumn('disability', 'Disability', DISALLOW_SORTING),
              createColumn('lgbtq', 'LGBTQ+', DISALLOW_SORTING),
              createColumn('country', 'Country', DISALLOW_SORTING),
              createColumn('location', 'Location', DISALLOW_SORTING)
            ]
          : []

      const pendingTabColumns =
        selectedTab !== 'all' && !isBatchDetails
          ? [
              createColumn('vendor_name', 'Vendor', {
                render: (cell: Cell, row: TimekeeperRow) =>
                  renderLink(cell, undefined, () => handleClickCell('batch_list', row)),
                ...DEFAULT_COLUMN_CONFIG
              }),
              createColumn('pending_count', 'Timekeepers Rates'),
              createColumn('full_name', 'Submitted By'),
              createColumn('created_date', 'Received', { render: renderFormattedDate })
            ]
          : []

      const completedTabColumns =
        selectedTab === 'completed' && !isBatchDetails
          ? [
              createColumn('completed_date', 'Completed', { render: renderFormattedDate }),
              createColumn('approver_list', 'Reviewed', {
                render: (cell: Cell) =>
                  Array.isArray(cell.content) && cell.content.length ? (
                    <AvatarList
                      size={cell.content.length > 1 ? 'sm' : 'md'}
                      limit={2}
                      avatarStyles={{ marginLeft: 0 }}
                      entries={cell.content}
                    />
                  ) : (
                    '---'
                  ),
                style: { textAlign: 'center' }
              })
            ]
          : []

      columns.push(...allTabColumns, ...pendingTabColumns, ...completedTabColumns)
    }

    return columns
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, isListView, isBatchDetails])
}

export const useFilteredTimekeepers = (timekeepers: TimekeeperRow[], selectedTab: string) =>
  useMemo(
    () =>
      timekeepers.map(tk => {
        return {
          ...tk,
          pending_count:
            selectedTab === 'pending'
              ? `${tk.pending_count || 0} out of ${tk.tk_count || 0} pending`
              : `${tk.approved_count ? `${tk.approved_count} approved` : ''}${
                  tk.approved_count && tk.rejected_count ? ', ' : ''
                } ${tk.rejected_count ? `${tk.rejected_count} rejected` : ''}`,
          full_name: !!tk.full_name && tk.full_name !== '-' ? tk.full_name : '---',
          percentage_change: !!tk.change_percentage ? String(tk.change_percentage) : '0',
          class_comparison: !!tk.class_comparison ? String(tk.class_comparison) : '0',
          classification: tk.classification || '---',
          approved: tk.approved || '0',
          pending: tk.pending || '0',
          rejected: tk.rejected || '0',
          id: tk.id || tk.timekeeper_file_id,
          modified_date: tk.modified_date || '---',
          completed_date: tk.completed_date,
          created_date: tk.created_date,
          effective: tk.effective,
          rack_rate: tk.rack_rate || '---',
          reason_for_increase: tk.reason_for_increase || '---',
          rejection_reason: tk.rejection_reason || '---',
          type: tk.type || 'Standard',
          country: tk.country || '---',
          location: tk.location || '---',
          practice_group: tk.practice_group || '---',
          bar_year: tk.bar_year || '---'
        }
      }),
    [timekeepers]
  )

export const emailRateRow = (rates: any) => {
  return rates.map((rate: any) => {
    return {
      status_reason: rate.status_reason || null,
      requested_rate: rate.requested_rate,
      rack_rate: rate.rack_rate || '---',
      currency: rate.currency || '---',
      effective_date: formatDate(rate.effective) || '---',
      status: rate.status,
      type: 'Standard'
    }
  })
}
