import { useEffect, useMemo, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { BsFillPersonCheckFill } from 'react-icons/bs'
import s from './EnhancedTimekeeepersContainer.scss'
import cn from 'classnames'
import APP_ACT from 'app/actions'
import { FaMoneyBill } from 'react-icons/fa'
import {
  Button,
  ButtonDropdown,
  CharLimitInput,
  DataTableWrapper,
  ModalContainer,
  Panel,
  useLoading
} from 'simple-core-ui'
import { ActionsPopover, Breadcrumbs, Tabs } from 'timekeepers/common'
import {
  NotifyData,
  NotifyUser,
  Params,
  SelectedBatchMeta,
  TimekeeperRow,
  TkFilters
} from './types'
import { makeGetRequest } from 'simple-core-ui/utils/api'
import { Updater, useImmer } from 'use-immer'
import { serializeTimekeepers } from './serializers'
import { useDispatch } from 'react-redux'
import { useColumns, useFilteredTimekeepers } from './tableDefinitions'
import { makeFormDataPost, makePatchRequest } from 'utils/api'
import pluralize from 'pluralize'
import { DatePickerSelect } from 'common/Selects'
import { format } from 'date-fns'
import { TimekeeperProfileDetails } from './TimekeeperProfileDetails'
import { openLink } from 'utils/helpers'
import { BULK_OPTIONS, TIMEKEEPERS_BASE_URL, URLS } from './constants'
import { useLocation, useSearchParams } from 'react-router-dom'
import queryString from 'query-string'
import { SendNotificationContent } from './SendNotificationContent'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { Filters } from './Filters'
import {
  createFiltersObjectFromQueryString,
  createQueryParams,
  decideCustomizableColumnsFieldName,
  getParamsFromQueryString
} from './utils'

const initialTableParams = {
  pageSize: 50,
  ordering: { columnKey: 'created_date', isDesc: true },
  search: '',
  page: 1,
  category: 'pending'
}

const initialNotifyData: NotifyData = {
  total_tks: 0,
  approved_tks: 0,
  rejected_tks: 0,
  other_tks: 0,
  client_name: '',
  tks: [],
  vendor_email: '',
  vendor_name: '',
  created_date: ''
}

const initialTkFilters = {
  timekeeper_id: null,
  tk_name: null,
  classification: null,
  currency: null,
  requested_rate: null,
  rack_rate: null,
  status: null,
  approved: null,
  rejected: null,
  pending: null
}

const EnhancedTimekeepersContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [localState, setLocalState] = useState({
    params: getParamsFromQueryString(searchParams, initialTableParams),
    view: searchParams.get('category') || 'rates',
    selectedTab: searchParams.get('tab') || 'pending',
    filters: { ...initialTkFilters, ...createFiltersObjectFromQueryString(searchParams) }
  })

  const { view, params, filters, selectedTab } = localState

  const [oldSelectedView, setOldSelectedView] = useState('')
  const [showBatchDetails, setShowBatchDetails] = useState(false)
  const [showTkProfile, setShowTkProfile] = useState(false)
  const [selectedBatchMeta, setSelectedBatchMeta] = useState<SelectedBatchMeta | null>(null)
  const [timekeepers, setTimekeepers]: [TimekeeperRow[], Updater<TimekeeperRow[]>] = useImmer<
    TimekeeperRow[]
  >([])
  const [timekeeperDetails, setTimekeeperDetails] = useState<TimekeeperRow | null>(null)
  const [totalEntries, setTotalEntries] = useState(0)
  const [pendingCount, setPendingCount] = useState(0)
  const [completedCount, setCompletedCount] = useState(0)
  const [allCount, setAllCount] = useState(0)
  const [showLoadingSkeleton, setShowLoadingSkeleton] = useState(true)
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [allRowsSelected, setAllRowsSelected] = useState(false)
  const [showRejectionReason, setShowRejectionReason] = useState(false)
  const [showEditEffectiveDateForm, setShowEditEffectiveDateForm] = useState(false)
  const [showEditEffectiveDateAlert, setShowEditEffectiveDateAlert] = useState(false)
  const [rejectionReason, setRejectionReason] = useState('')
  const [hasCharLimitError, setHasCharLimitError] = useState(false)
  const [effectiveDate, setEditEffectiveDate] = useState<Date>(new Date())
  const [selectedTimekeeperBatch, setSelectedTimekeeperBatch] = useState<TimekeeperRow | null>(null)
  const [showSendNotification, setShowSendNotification] = useState(false)
  const [notifyData, setNotifyData] = useState<NotifyData>(initialNotifyData)
  const [notifyUser, setNotifyUser] = useState<NotifyUser[]>([])

  const [isLoading, withLoadingLocksGetTks] = useLoading()

  const isRatesView = view === 'rates'
  const isTkListView = view === 'list'

  const dispatch = useDispatch()

  const resetState = () => {
    setRejectionReason('')
    setShowEditEffectiveDateForm(false)
    setShowEditEffectiveDateAlert(false)
    setShowSendNotification(false)
    setShowRejectionReason(false)
    setEditEffectiveDate(new Date())
  }

  const fetchCounts = async () => {
    const url = `${TIMEKEEPERS_BASE_URL}${URLS.TIMEKEEPERS_RATES_COUNT}`

    try {
      const { completedBatchCount, pendingBatchCount, totalRates } = await makeGetRequest(url)

      setCompletedCount(completedBatchCount)
      setPendingCount(pendingBatchCount)
      setAllCount(totalRates)
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  const location = useLocation()
  const queryParams = queryString.parse(location.search)

  const { selected_batch_id } = queryParams

  const updateSearchParams = (tableParams: Params, selectedTab: string) => {
    searchParams.set('columnKey', (tableParams || params).ordering.columnKey)
    searchParams.set('isDesc', String((tableParams || params).ordering.isDesc))
    searchParams.set('search', encodeURIComponent((tableParams || params).search))
    searchParams.set('page_size', String((tableParams || params).pageSize))
    searchParams.set('page_number', String((tableParams || params).page))
    if (view === 'rates') searchParams.set('tab', selectedTab)
    if (view === 'list') searchParams.delete('tab')
    searchParams.set('category', view)
    setSearchParams(searchParams)

    setLocalState(state => ({
      ...state,
      params: tableParams
    }))
  }

  const fetchTimekeepers = async (
    selectedTab: string,
    tableParams: Params,
    vendorId: string = '',
    batchId: number = 0,
    hasSearchParams: boolean = false,
    filter?: TkFilters
  ) => {
    let urlParamsString = ''
    if (vendorId && batchId) {
      urlParamsString = `vendor=${vendorId}&batch_id=${batchId}`
    } else {
      searchParams.delete('vendor')
      searchParams.delete('batch')
    }
    const appendingUrl = isRatesView
      ? ['pending', 'completed'].includes(selectedTab) && !urlParamsString
        ? URLS.TIMEKEEPERS_BATCH_LIST
        : `${URLS.TIMEKEEPERS_RATES_LIST}?${urlParamsString}`
      : URLS.ALL_TIMEKEEPERS_LIST

    const url = `${TIMEKEEPERS_BASE_URL}${appendingUrl}`

    try {
      let urlTableParams = {
        columnKey: hasSearchParams ? searchParams.get('columnKey') : tableParams.ordering.columnKey,
        search: hasSearchParams
          ? decodeURIComponent(searchParams.get('search') ?? '')
          : tableParams.search,
        page_number: hasSearchParams ? searchParams.get('page_number') : tableParams.page,
        page_size: hasSearchParams ? searchParams.get('page_size') : tableParams.pageSize,
        ...(0
          ? searchParams.get('isDesc')
            ? { isDesc: searchParams.get('isDesc') }
            : {}
          : tableParams.ordering.isDesc
          ? { isDesc: tableParams.ordering.isDesc }
          : {})
      }

      const f = filter || filters

      for (const key in f) {
        const queryParam = createQueryParams(f?.[key as keyof TkFilters], key)
        if (queryParam) {
          urlTableParams = {
            ...urlTableParams,
            [key]: queryParam
          }
          searchParams.set(key, queryParam)
        } else {
          searchParams.delete(key)
        }
      }

      const urlParams =
        selectedTab === 'completed'
          ? { params: { batch: selectedTab, ...urlTableParams } }
          : {
              params: urlTableParams
            }

      const response = await withLoadingLocksGetTks(makeGetRequest(url, urlParams))
      const { rows, totalEntries } = response
      if (!hasSearchParams) {
        updateSearchParams(tableParams, selectedTab)
      }

      if (selected_batch_id) {
        const filteredSelectedRows = rows.filter(
          (row: TimekeeperRow) => String(row.timekeeper_file_id) === String(selected_batch_id)
        )
        setSelectedTimekeeperBatch(filteredSelectedRows[0] || null)
      }

      if (urlParamsString) {
        setSelectedBatchMeta({
          vendorId: vendorId,
          vendorName: response.vendor_name,
          submittedBy: response.created_by_name,
          email: response.created_by_email,
          batchId: batchId
        })
        setShowBatchDetails(true)
      }
      setTimekeepers(serializeTimekeepers(rows, isRatesView, selectedTab, Boolean(urlParamsString)))
      setTotalEntries(totalEntries)
      setShowLoadingSkeleton(true)
      setSelectedRows([])
      setAllRowsSelected(false)
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  useEffect(() => {
    const vendorId = searchParams.get('vendor') || ''
    const batchId = Number(searchParams.get('batch')) || 0
    fetchTimekeepers(selectedTab, params, vendorId, batchId, Boolean(searchParams.size))
    fetchCounts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tabs = useMemo(() => {
    return [
      {
        label: 'Pending Batches',
        value: 'pending',
        count: pendingCount
      },
      {
        label: 'Completed Batches',
        value: 'completed',
        count: completedCount
      },
      {
        label: 'All rates',
        value: 'all',
        count: allCount
      }
    ]
  }, [pendingCount, completedCount, allCount])

  const handleClickCell = (type: string, rowData: TimekeeperRow) => {
    if (type === 'batch_list') {
      const newParams = {
        ...initialTableParams,
        ordering: { columnKey: 'timekeeper', isDesc: false }
      }
      setLocalState(state => ({
        ...state,
        params: newParams
      }))
      setShowBatchDetails(true)
      searchParams.set('vendor', rowData.vendor_id)
      searchParams.set('batch', String(rowData.timekeeper_file_id))
      setSearchParams(searchParams)
      setSelectedBatchMeta({
        vendorId: rowData.vendor_id,
        vendorName: rowData.vendor_name,
        submittedBy: rowData.full_name,
        email: rowData.email,
        batchId: rowData.timekeeper_file_id
      })
      fetchTimekeepers(selectedTab, params, rowData.vendor_id, rowData.timekeeper_file_id)
    }
  }

  const handleApprove = async (row?: TimekeeperRow) => {
    setShowLoadingSkeleton(false)
    const isBulkApproval = selectedRows.length

    try {
      const url = `${TIMEKEEPERS_BASE_URL}${URLS.TIMEKEEPER_UPDATE}`

      const data = {
        status: 'approve',
        batch_id: selectedBatchMeta?.batchId,
        tk_ids: row?.id
          ? row.id
          : isBulkApproval
          ? selectedRows
          : selectedBatchMeta?.rejectionRowIds,
        vendor_id: selectedBatchMeta?.vendorId
      }

      const rowCount = selectedRows.length || 1

      await withLoadingLocksGetTks(makePatchRequest(url, data))
      isBulkApproval &&
        dispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: 'Success',
            message: `${pluralize('rate', rowCount, true)} successfully approved`,
            level: 'success'
          }
        })
      fetchTimekeepers(selectedTab, params, selectedBatchMeta?.vendorId, selectedBatchMeta?.batchId)
      fetchCounts()
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
      resetState()
      setShowLoadingSkeleton(true)
    }
  }

  const handleResetStatus = async (row?: TimekeeperRow) => {
    setShowLoadingSkeleton(false)

    try {
      const url = `${TIMEKEEPERS_BASE_URL}${URLS.TIMEKEEPER_UPDATE}`

      const data = {
        status: '',
        status_reason: '',
        batch_id: selectedBatchMeta?.batchId,
        tk_ids: row?.id,
        vendor_id: selectedBatchMeta?.vendorId
      }

      await withLoadingLocksGetTks(makePatchRequest(url, data))
      fetchTimekeepers(selectedTab, params, selectedBatchMeta?.vendorId, selectedBatchMeta?.batchId)
      fetchCounts()
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
      setShowLoadingSkeleton(true)
    }
  }

  const handleReject = async (row?: TimekeeperRow) => {
    setShowLoadingSkeleton(false)
    setShowRejectionReason(true)
    setSelectedBatchMeta({
      ...(selectedBatchMeta as SelectedBatchMeta),
      rejectionRowIds: row?.id || selectedRows
    })
  }

  const handleEditEffectiveDate = async () => {
    setShowEditEffectiveDateForm(true)
    setSelectedBatchMeta({
      ...(selectedBatchMeta as SelectedBatchMeta),
      rejectionRowIds: selectedRows
    })
  }

  useEffect(() => {
    if (!selectedTimekeeperBatch?.timekeeper_file_id) return

    handleClickCell('batch_list', selectedTimekeeperBatch)
  }, [selectedTimekeeperBatch?.timekeeper_file_id])

  const onConfirmRejection = async () => {
    const isBulkRejection = selectedRows.length

    try {
      const url = `${TIMEKEEPERS_BASE_URL}${URLS.TIMEKEEPER_UPDATE}`

      const data = {
        batch_id: selectedBatchMeta?.batchId,
        status: 'reject',
        reason: rejectionReason,
        tk_ids: isBulkRejection ? selectedRows : selectedBatchMeta?.rejectionRowIds,
        vendor_id: selectedBatchMeta?.vendorId
      }

      await withLoadingLocksGetTks(makePatchRequest(url, data))

      const rowCount = selectedRows.length || 1

      isBulkRejection &&
        dispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: 'Success',
            message: `${pluralize('rate', rowCount, true)} successfully rejected`,
            level: 'success'
          }
        })
      fetchTimekeepers(selectedTab, params, selectedBatchMeta?.vendorId, selectedBatchMeta?.batchId)
      fetchCounts()
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
      setShowLoadingSkeleton(true)
    }
    resetState()
    setShowRejectionReason(false)
  }

  const onConfirmEditEffectiveDate = async () => {
    setShowLoadingSkeleton(false)

    try {
      const url = `${TIMEKEEPERS_BASE_URL}${URLS.TIMEKEEPER_UPDATE}`

      const data = {
        batch_id: selectedBatchMeta?.batchId,
        effective: format(effectiveDate as Date, 'MM/dd/yyyy'),
        tk_ids: selectedRows.length ? selectedRows : selectedBatchMeta?.rejectionRowIds,
        vendor_id: selectedBatchMeta?.vendorId
      }

      await withLoadingLocksGetTks(makePatchRequest(url, data))

      const rowCount = selectedRows.length || 1

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: `${pluralize('rate', rowCount, true)} successfully updated`,
          level: 'success'
        }
      })
      fetchTimekeepers(selectedTab, params, selectedBatchMeta?.vendorId, selectedBatchMeta?.batchId)
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
    resetState()
    setShowLoadingSkeleton(true)
  }

  const handleTimekeeperClick = async (row: TimekeeperRow) => {
    setShowTkProfile(true)
    setTimekeeperDetails(row)
  }

  const columns = useColumns(
    selectedTab,
    isTkListView,
    handleClickCell,
    handleTimekeeperClick,
    showBatchDetails,
    handleApprove,
    handleReject,
    handleResetStatus
  )

  const filteredTks = useFilteredTimekeepers(timekeepers, selectedTab)

  useEffect(() => {
    const newParams = {
      page: Number(searchParams.get('page_number')) || 1,
      pageSize: Number(searchParams.get('page_size')) || 10,
      search: decodeURIComponent(searchParams.get('search') ?? ''),
      ordering: isRatesView
        ? selectedTab === 'all'
          ? { columnKey: 'effective', isDesc: true }
          : { columnKey: 'created_date', isDesc: true }
        : { columnKey: 'modified_date', isDesc: true },
      category: isRatesView ? selectedTab : initialTableParams.category
    }

    setLocalState(state => ({
      ...state,
      params: newParams
    }))

    if (isRatesView && oldSelectedView === 'list') {
      fetchTimekeepers('pending', initialTableParams)
      setOldSelectedView('')
    } else if (isTkListView && searchParams.get('category') === 'list') {
      fetchTimekeepers(selectedTab, newParams)
    } else if (isTkListView) {
      setLocalState(state => ({
        ...state,
        filters: initialTkFilters
      }))

      fetchTimekeepers(selectedTab, newParams, '', 0, false, initialTkFilters)
    }
  }, [selectedTab, isRatesView, isTkListView, oldSelectedView])

  const clearBulkSelection = () => {
    setSelectedRows([])
    setAllRowsSelected(false)
  }

  const onChangeTabCb = (selectedTab: string) => {
    setShowBatchDetails(false)
    if (selectedTab === 'all') {
      const newParams = {
        ...initialTableParams,
        ordering: { columnKey: 'effective', isDesc: true }
      }
      setLocalState(state => ({
        ...state,
        params: newParams
      }))
      fetchTimekeepers(selectedTab, newParams, '', 0, false, initialTkFilters)
    } else {
      setLocalState(state => ({
        ...state,
        params: initialTableParams
      }))
      fetchTimekeepers(selectedTab, initialTableParams, '', 0, false, initialTkFilters)
    }
    clearBulkSelection()
    setLocalState(state => ({
      ...state,
      filters: initialTkFilters
    }))
  }

  const changeTab = (tab: string) => {
    if (tab !== selectedTab) {
      setLocalState(state => ({
        ...state,
        params: initialTableParams,
        selectedTab: tab
      }))
      onChangeTabCb(tab)
    }
  }

  const selectRow = ({ id }: { id: number }) => {
    setAllRowsSelected(false)

    if (selectedRows.includes(id)) {
      setSelectedRows(prevSelectedRows => prevSelectedRows.filter(rowId => rowId !== id))
    } else {
      setSelectedRows(prevSelectedRows => [...new Set([...prevSelectedRows, id])])
    }
  }

  const selectAllRows = () => {
    setAllRowsSelected(allRowsSelected => !allRowsSelected)
    setSelectedRows(allRowsSelected ? [] : filteredTks.map(t => t.id))
  }

  const updateTable = async (params: Params) => {
    setShowLoadingSkeleton(false)
    setLocalState({
      ...localState,
      params
    })

    const vendorId = showBatchDetails ? selectedBatchMeta?.vendorId : ''
    const batchId = showBatchDetails ? selectedBatchMeta?.batchId : 0

    fetchTimekeepers(selectedTab, params, vendorId, batchId)
  }

  const triggerBulkAction = (option: { value: string; label: string }) => {
    switch (option.value) {
      case 'approve':
        handleApprove()
        break
      case 'reject':
        handleReject()
        break
      case 'edit':
        handleEditEffectiveDate()
        break
      case 'default':
        break
    }
  }

  const bulkActionsButton = (
    <ButtonDropdown
      displayText={`Bulk Actions (${selectedRows.length})`}
      options={BULK_OPTIONS}
      style={{ padding: '10px', borderRadius: '2px' }}
      listStyles={{ top: '42px' }}
      listItemStyles={{ fontSize: '14px' }}
      onSelect={triggerBulkAction}
    />
  )

  const downloadBatchRate = (row: TimekeeperRow) => {
    const downloadUrl = `${TIMEKEEPERS_BASE_URL}${URLS.TIMEKEEPERS_RATES_LIST}?isCsv=true&batch_id=${row.timekeeper_file_id}&vendor=${row.vendor_id}`
    openLink(downloadUrl)
  }

  const rowActions = (row: TimekeeperRow) => [
    {
      name: 'VIEW',
      text: 'View Details',
      onClick() {
        if (isRatesView && ['pending', 'completed'].includes(selectedTab)) {
          handleClickCell('batch_list', row)
        } else {
          handleTimekeeperClick(row)
        }
      }
    },
    ...(selectedTab !== 'all' && !isTkListView
      ? [
          {
            name: 'DOWNLOAD',
            text: 'Download',
            onClick() {
              downloadBatchRate(row)
            }
          }
        ]
      : [])
  ]

  const renderCustomAction = (row: TimekeeperRow) => (
    <ActionsPopover actions={rowActions(row)} timekeeper={row} />
  )

  const downloadRateCsv = () => {
    const isDesc = params.ordering.isDesc ? `&isDesc=${params.ordering.isDesc}` : ''
    if (selectedTab === 'all') {
      const downloadUrl = `${TIMEKEEPERS_BASE_URL}${URLS.TIMEKEEPERS_RATES_LIST}?isCsv=true&columnKey=${params.ordering.columnKey}&search=${params.search}${isDesc}`
      openLink(downloadUrl)
    } else if (showBatchDetails) {
      const downloadUrl = `${TIMEKEEPERS_BASE_URL}${URLS.TIMEKEEPERS_RATES_LIST}?isCsv=true&batch_id=${selectedBatchMeta?.batchId}&vendor=${selectedBatchMeta?.vendorId}&columnKey=${params.ordering.columnKey}&search=${params.search}${isDesc}`
      openLink(downloadUrl)
    }
  }

  const renderRejectionReasonModalContent = (reason: string) => (
    <>
      <p className={s.modalText}>Are you sure you want to reject the selected rate(s)?</p>
      <p className={s.fieldTitle}>
        Reason for Rejection <span>*</span>
      </p>
      <CharLimitInput
        rows={4}
        type="textarea"
        cssClass={s.textarea}
        value={reason}
        onChangeCb={e => setRejectionReason(e.target.value)}
        dynamicCharCalculation
        charMsgSuffix=" characters"
        hasLengthErrorCb={(hasError: boolean) => setHasCharLimitError(hasError)}
      />
    </>
  )

  const editModalcontent = (
    <>
      <p className={s.modalText}>Update the effective date(s) for your selected rate(s).</p>
      <p className={s.fieldTitleRegular}>Effective Date</p>
      <DatePickerSelect
        value={effectiveDate}
        selectDate={(date?: Date) => {
          setEditEffectiveDate(date as Date)
        }}
        canSave={value => {
          return Boolean(value)
        }}
      />
    </>
  )

  const fetchNotifyData = async () => {
    try {
      const response = await makeGetRequest(
        `/timekeepers/tk_rates_notify_firm_detail/?vendor=${selectedBatchMeta?.vendorId}&batch_id=${selectedBatchMeta?.batchId}`
      )
      setNotifyUser([
        {
          label: response.vendor_email,
          value: response.vendor_email,
          email: response.vendor_email,
          fullName: response.vendor_name
        }
      ])
      setNotifyData(response)
      setShowSendNotification(true)
    } catch (e) {}
  }

  const rightActions = useMemo(() => {
    return (
      <span className={s.rightActions}>
        {showBatchDetails ? (
          <Button
            style={{ padding: '10px 15px' }}
            key="notifications"
            onClick={fetchNotifyData}
            isPrimary
            isOutline
            hasNewDesign
          >
            Send Notifications
          </Button>
        ) : null}
        {showBatchDetails || selectedTab === 'all' || isTkListView ? (
          <Button
            style={{ padding: '10px 15px' }}
            key="download"
            onClick={downloadRateCsv}
            isPrimary
            isOutline
            hasNewDesign
          >
            Download
          </Button>
        ) : null}
      </span>
    )
  }, [showBatchDetails, selectedTab, isTkListView, localState])

  const sendNotificationTitle = (
    <>
      <div style={{ fontSize: '16px' }}>Send Notification</div>
      <span>
        <AiOutlineInfoCircle className={s.info} />
      </span>
      <span className={s.notificationSubheader}>
        Vendors receive a daily email automatically when reviews are completed.
      </span>
    </>
  )

  const submitNotify = async () => {
    try {
      const users = notifyUser.map(n => n.value).join(';')
      const body = {
        message_to: users,
        batch_id: selectedBatchMeta?.batchId,
        vendor_id: selectedBatchMeta?.vendorId
      }

      setShowSendNotification(false)
      const response = await makeFormDataPost(`/timekeepers/n_f/`, body)

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          level: 'success',
          message: `Email notification has been sent to ${notifyUser.length} user(s)`
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const clearFilters = () => {
    setLocalState(state => ({
      ...state,
      filters: initialTkFilters
    }))
    if (showBatchDetails) {
      fetchTimekeepers(
        selectedTab,
        params,
        searchParams.get('vendor') || '',
        parseInt(searchParams.get('batch') || '0'),
        false,
        initialTkFilters
      )
    } else {
      fetchTimekeepers(selectedTab, params, '', 0, false, initialTkFilters)
    }
  }

  return (
    <section>
      {showTkProfile && (
        <TimekeeperProfileDetails
          timekeeper={timekeeperDetails}
          setShowTkProfile={setShowTkProfile}
          setShowLoadingSkeleton={setShowLoadingSkeleton}
        />
      )}
      <section className={cn(s.container, { [s.noActions]: showTkProfile })}>
        <section className={s.panel}>
          <a href="/v2/administration/">
            <IoIosArrowBack />
            {' Back to Administration'}
          </a>

          <div className={cn(s.panelContainer, { [s.withShadow]: isRatesView })}>
            <div className={s.innerPanel}>
              <div className={s.header}>
                <h2 className={s.title} data-testid="title">
                  Timekeepers
                </h2>
                <div className={s.tabs}>
                  <span
                    className={cn({ [s.selected]: isRatesView })}
                    onClick={() => {
                      if (isTkListView) {
                        setOldSelectedView('list')
                        setLocalState(state => ({
                          ...state,
                          params: initialTableParams,
                          selectedTab: 'pending',
                          view: 'rates'
                        }))
                        setShowBatchDetails(false)
                        setSelectedBatchMeta(null)
                      }
                    }}
                  >
                    <FaMoneyBill /> Rate Review
                  </span>
                  <span
                    className={cn({ [s.selected]: isTkListView })}
                    onClick={() => {
                      if (isRatesView) {
                        setOldSelectedView('rates')
                        setLocalState(state => ({
                          ...state,
                          params: initialTableParams,
                          view: 'list'
                        }))
                        setShowBatchDetails(false)
                        setSelectedBatchMeta(null)
                      }
                    }}
                  >
                    <BsFillPersonCheckFill /> All Timekeepers
                  </span>
                </div>
                {rightActions}
              </div>
              {isRatesView ? (
                <Tabs
                  listClassName={s.alignRight}
                  tabs={tabs}
                  selectedTab={selectedTab}
                  setSelectedTab={changeTab}
                />
              ) : null}
              <Panel
                className={s.panel}
                bodyClassName={s.panel}
                headerClassName={s.headerClassName}
                isBodyOnly={!showBatchDetails || isTkListView}
                breadcrumbs={
                  showBatchDetails && selectedBatchMeta ? (
                    <Breadcrumbs
                      items={[
                        {
                          title: 'Vendors',
                          clickCb: () => {
                            clearBulkSelection()
                            setShowBatchDetails(false)
                            setSelectedBatchMeta(null)
                            fetchTimekeepers(selectedTab, params)
                          },
                          ...(selected_batch_id ? { redirectTo: '/v2/timekeepers/' } : {})
                        },
                        {
                          title: selectedBatchMeta.vendorName,
                          redirectTo: `/invoices/vendors/${selectedBatchMeta.vendorId}/?fromTimekeepers=true`
                        }
                      ]}
                    />
                  ) : null
                }
              >
                {showBatchDetails ? (
                  <p className={s.vendorMeta}>
                    Submitted by {selectedBatchMeta?.submittedBy} ({' '}
                    <a href={`mailto:${selectedBatchMeta?.email}`}>{selectedBatchMeta?.email}</a> )
                  </p>
                ) : null}
                <DataTableWrapper
                  alwaysShowLoadingSkeleton={showLoadingSkeleton}
                  hasActions={!showBatchDetails}
                  alwaysShowActions
                  isLoading={isLoading}
                  remotePagination
                  params={params}
                  categories={[]}
                  rows={filteredTks}
                  totalEntries={totalEntries}
                  columns={columns}
                  customAction={renderCustomAction}
                  updateTable={updateTable}
                  entryLanguage={
                    isTkListView
                      ? { singular: 'Timekeeper', plural: 'Timekeepers' }
                      : selectedTab === 'all' || showBatchDetails
                      ? {
                          singular: 'Rate',
                          plural: 'Rates'
                        }
                      : {
                          singular: 'Batch',
                          plural: 'Batches'
                        }
                  }
                  hasTooltip
                  panelStyles={{ boxShadow: 'none', padding: 0 }}
                  bulkActions={bulkActionsButton}
                  selectAllRows={showBatchDetails ? selectAllRows : undefined}
                  selectRow={showBatchDetails ? selectRow : undefined}
                  selectedRows={new Set(selectedRows)}
                  allRowsSelected={allRowsSelected}
                  filters={
                    view === 'list' || selectedTab === 'all' || showBatchDetails ? (
                      <Filters
                        tkFilters={filters}
                        setTkFilters={filters => {
                          setLocalState(state => ({
                            ...state,
                            filters
                          }))
                          if (showBatchDetails) {
                            fetchTimekeepers(
                              selectedTab,
                              params,
                              searchParams.get('vendor') || '',
                              parseInt(searchParams.get('batch') || '0'),
                              false,
                              filters
                            )
                          } else {
                            fetchTimekeepers(selectedTab, params, '', 0, false, filters)
                          }
                        }}
                        clearFilters={clearFilters}
                        baseUrl={'/timekeepers/timekeeper_filter_options'}
                      />
                    ) : null
                  }
                  customizableColumns={decideCustomizableColumnsFieldName(
                    view,
                    selectedTab,
                    showBatchDetails
                  )}
                />
              </Panel>
            </div>
          </div>
          {showRejectionReason && (
            <ModalContainer
              contentClassName={s.modal}
              title="Reject Timekeeper Rate"
              content={renderRejectionReasonModalContent(rejectionReason)}
              confirmText="Yes, Reject"
              size="sm"
              hasNewButtons
              contentStyle={{ padding: '10px 24px 30px', minHeight: 'auto' }}
              confirmCb={onConfirmRejection}
              cancelCb={resetState}
              isDisabled={hasCharLimitError || !rejectionReason.trim()}
            />
          )}
          {showEditEffectiveDateForm && (
            <ModalContainer
              contentClassName={s.modal}
              title={`Edit ${selectedRows.length} rate(s)`}
              content={editModalcontent}
              confirmText="Update"
              size="sm"
              hasNewButtons
              contentStyle={{ padding: '10px 24px 30px', minHeight: 'auto' }}
              confirmCb={() => setShowEditEffectiveDateAlert(true)}
              cancelCb={resetState}
              isDisabled={!effectiveDate}
            />
          )}
          {showEditEffectiveDateAlert && (
            <ModalContainer
              contentClassName={s.modal}
              title={`Update ${selectedRows.length} rate(s)`}
              content="Are you sure you want to update the rate(s) you selected?"
              confirmText="Update"
              size="sm"
              hasNewButtons
              contentStyle={{ padding: '10px 24px 30px', minHeight: 'auto' }}
              confirmCb={onConfirmEditEffectiveDate}
              cancelCb={resetState}
            />
          )}
          {showSendNotification && (
            <ModalContainer
              contentClassName={s.modal}
              title={sendNotificationTitle}
              content={
                <SendNotificationContent
                  user={notifyUser}
                  setUser={setNotifyUser}
                  vendorId={selectedBatchMeta?.vendorId || ''}
                  notifyData={notifyData}
                />
              }
              size="lg"
              confirmText="Send Email"
              hasNewButtons
              contentStyle={{ padding: '10px 24px 30px', minHeight: 'auto' }}
              confirmCb={submitNotify}
              cancelCb={resetState}
            />
          )}
        </section>
      </section>
    </section>
  )
}

export default EnhancedTimekeepersContainer
