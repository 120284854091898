interface BatchRequestBase {
  tk_ids: number | number[]
  vendor_id: string
  batch_id: number
}

interface BatchRequestEffectiveDate extends BatchRequestBase {
  effective: string
}

interface BatchRequestStatus extends BatchRequestBase {
  status: string
  status_reason?: string
}

interface BatchRequestStatus extends BatchRequestBase {
  status: string
  status_reason?: string
}

interface AllRatesTkInfoAPI {
  bar_year: string
  classification: string
  country: string
  created_date: string
  disability: string
  email: string
  first_name: string
  gender: string
  id: number
  last_name: string
  lgbtq: string
  location: string
  phone: string | null
  practice_group: string
  race: string
  timekeeper_id: string
  vendor_id: string
  vendor_name: string
  veteran_status: string
}

export interface TimekeeperProfileDetailsRatesAPI {
  id: number
  currency: string
  reason_for_increase: string | null
  effective: string
  requested_rate: string
  status_updated: string | null
  status: string
  status_reason: string | null
  rack_rate: string | null
  class_comparison: number | null
  change_percentage: string | null
  classification: string
  type: string
}

interface TimekeeperProfileUpdatesAPI {
  attribute: string
  from_value: string
  to_value: string
}

export interface AllRatesAPI {
  currency: string
  diversity: string
  effective: string
  id: number
  is_new_rate: boolean
  is_rate_used: boolean | null
  rack_rate: string | null
  reason_for_increase: string | null
  rejection_reason: null | null
  requested_rate: string
  status: string
  status_reason: string | null
  status_updated: string | null
  timekeeper: AllRatesTkInfoAPI
}

export interface Cell {
  columnKey: string
  content:
    | string
    | {
        name: string
        color: string
      }
    | {
        value: string
        label: string
      }
    | {
        value: string
        label: string
      }[]
    | {
        id?: number
        label: string
      }[]
    | Record<string, number | string>
}

export interface CompletedBatchesAPI {
  approved_count: number
  approver_list: Array<string>
  completed_date: string
  created_date: string
  email: string | null
  full_name: string
  rejected_count: number
  timekeeper_file_id: number
  tk_count: number
  vendor_id: string
  vendor_name: string
}

export interface DiversityKeyValuePairs {
  key: JSX.Element | string
  value: JSX.Element | string
  keyClass?: string
  valueClass?: string
}

export interface HistoryActionItemAPI {
  action: string
  attribute: string
  cg_user_name: string | null
  from_value: string | null
  rates: HistoryRatesRowAPI[] | null
  sl_user: { name: string; email: string } | null
  to_value: string | null
}

export interface HistoryDataAPI {
  attribute: string
  action: string
  from_value: string | null
  to_value: string | null
  rates: HistoryRatesRowAPI[]
  cg_user_name: string
  sl_user: SLUserAPI | null
}

export interface HistoryRatesRowAPI {
  id: number
  currency: string
  reason_for_increase: string
  effective: string
  requested_rate: string
  status_updated: string | null
  status: string
  status_reason: string | null
  rack_rate: string | null
}

export interface KeyValuePairs {
  key: string
  value: string
}

export interface Params {
  pageSize: number
  search: string
  page: number
  category: string
  ordering: {
    columnKey: string
    isDesc: boolean
  }
}

export interface PendingBatchesAPI {
  created_date: string
  email: string | null
  full_name: string
  pending_count: number
  timekeeper_file_id: number
  tk_count: number
  vendor_id: string
  vendor_name: string
}

export interface Row {
  id: string
  cells: Cell[]
}

export interface SelectedBatchMeta {
  vendorId: string
  vendorName: string
  submittedBy: string
  email: string | null
  batchId: number
  rejectionRowIds?: number | number[]
}

export interface TimekeeperHistoryInfoAPI {
  tk_history: TimekeeperHistoryInfoAPI[]
}

export interface TimekeeperHistoryInfo {
  created_date: string
  data: HistoryDataAPI[] | HistoryActionItemAPI
}

export interface TimekeeperListAPI {
  approved: number
  bar_year: string
  classification: string
  country: string | null
  created_date: string
  disability: string | null
  diversity: number
  email: string
  first_name: string
  gender: string
  id: number
  is_new_tk: boolean
  last_name: string
  lgbtq: string | null
  location: string
  modified_date: string
  num_rates: number
  pending: number
  phone: string
  practice_group: string
  race: string
  rejected: number
  timekeeper_id: string
  vendor_name: string
  veteran_status: string
}

export interface TimekeeperProfileDetailsAPI {
  pendingRates: Array<TimekeeperProfileDetailsRatesAPI>
  historicalRates: Array<TimekeeperProfileDetailsRatesAPI>
  tkUpdates: Array<TimekeeperProfileUpdatesAPI>
}

export interface TimekeeperProfileInfo extends TimekeeperProfileDetailsAPI {
  isNew?: boolean
  generalInfo: {
    classification: string
    location: string
    email: string
    practiceGroup: string
    country: string
    barYear: string
  }
  diversityInfo: {
    race: string
    disability: string
    gender: string
    lgbtq: string
    veteran_status: string
  }
  timekeeperMeta: {
    name: string
    id: string
    created: string
  }
}

export interface TimekeeperRow {
  approved: number
  approved_count: number
  approver_list: Array<{ label: string; id: string }>
  bar_year: string | null
  change_percentage: string
  class_comparison: string
  classification: string
  completed_date: string
  created_date: string
  country: string | null
  currency: string
  diversity: string
  effective: string
  email: string | null
  full_name: string
  id: number
  is_new_rate?: boolean
  is_new_tk?: boolean
  is_rate_used?: boolean
  location: string | null
  matter?: string
  matter_id?: string
  modified_date: string
  pending: number
  pending_count: number
  practice_group: string | null
  rack_rate: string
  reason_for_increase: string
  rejection_reason: string
  rejected: number
  rejected_count: number
  requested_rate: string
  status: string
  timekeeper: string
  timekeeper_file_id: number
  timekeeper_id: string
  timekeeper_row_id: string
  tk_count: number
  type?: string
  vendor_id: string
  vendor_name: string
  race: string
  disability: string
  gender: string
  lgbtq: string
  veteran_status: string
}

export interface SelectedBatchMeta {
  vendorId: string
  vendorName: string
  submittedBy: string
  email: string | null
  batchId: number
}

export interface SelectedBatchMeta {
  vendorId: string
  vendorName: string
  submittedBy: string
  email: string | null
  batchId: number
  rejectionRowIds?: number | number[]
}

export interface SLUserAPI {
  email: string
  name: string
}

export type BatchRequest = BatchRequestEffectiveDate | BatchRequestStatus

export interface NotifyUser {
  email: string
  fullName: string
  label: string | JSX.Element
  value: string
}

export interface NotifyTimekeeperData {
  is_new_tk: boolean
  timekeeper_name: string
  timekeeper_id: string
  classification: string
  rates: AllRatesAPI[]
}

export interface NotifyData {
  total_tks: number
  approved_tks: number
  rejected_tks: number
  other_tks: number
  client_name: string
  tks?: NotifyTimekeeperData[]
  vendor_email: string
  vendor_name: string
  created_date: string
}
export interface FilterOption {
  label: string
  value: string | number
}

export type Filters = {
  operator: FilterOption | null
  values?: FilterOption[] | null
}

enum TkFilterKeys {
  tkId = 'timekeeper_id',
  tk_name = 'tk_name',
  classification = 'classification',
  currency = 'currency',
  requested_rate = 'requested_rate',
  rack_rate = 'rack_rate',
  status = 'status',
  approved = 'approved',
  rejected = 'rejected',
  pending = 'pending'
}

export type TkFilters = {
  [key in TkFilterKeys]: Filters | null
}

export const statusesMap: { [key: string]: string } = {
  None: 'Pending',
  approve: 'Approved',
  reject: 'Rejected'
} as const
