export const BULK_OPTIONS = [
  { label: 'Approve', value: 'approve' },
  { label: 'Reject', value: 'reject' },
  { label: 'Edit Effective Date(s)', value: 'edit' }
]

export const TIMEKEEPERS_BASE_URL = '/timekeepers'

export const DEFAULT_COLUMN_CONFIG = { isDefault: true }

export const DISALLOW_SORTING = { isSortable: false }

export const URLS = {
  TIMEKEEPERS_RATES_COUNT: '/tk_rates_count/',
  TIMEKEEPERS_BATCH_LIST: '/timekeeper_batch_list/',
  TIMEKEEPERS_RATES_LIST: '/timekeeper_rates_list/',
  ALL_TIMEKEEPERS_LIST: '/timekeepers_list/',
  TIMEKEEPER_UPDATE: '/timekeeper_update/'
}

export const EXISTING_TK_TABS = [
  {
    label: 'Rates',
    value: 'rates'
  },
  {
    label: 'Profile',
    value: 'profile'
  },
  {
    label: 'History',
    value: 'history'
  }
]

export const NEW_TK_TABS = [
  {
    label: 'Rates and Requests',
    value: 'rates'
  },
  {
    label: 'History',
    value: 'history'
  }
]

export const OPERATORS = {
  IS: 'IS',
  IS_NOT: 'IS_NOT',
  IS_SET: 'IS_SET',
  IS_NOT_SET: 'IS_NOT_SET',
  IS_BEFORE: 'IS_BEFORE',
  IS_AFTER: 'IS_AFTER',
  IS_BETWEEN: 'IS_BETWEEN',
  WITH_COMMENTS: 'WITH_COMMENTS',
  WITHOUT_COMMENTS: 'WITHOUT_COMMENTS',
  WITH_ATTACHMENTS: 'WITH_ATTACHMENTS',
  WITHOUT_ATTACHMENTS: 'WITHOUT_ATTACHMENTS',
  NEEDS_REVIEW: 'NEEDS_REVIEW',
  DOES_NOT_NEED_REVIEW: 'DOES_NOT_NEED_REVIEW'
}
