import { VerticalTabs, VerticalTab } from 'simple-core-ui'
import { Reviewers } from './Reviewers'
import { Permissions } from './Permissions'
import { AllocationsContainer } from './Allocations'
import { Notifications } from './Notifications'
import { hasModule } from 'utils/helpers'
import { Notes } from './Notes'
import { SCOPE, MODULE } from 'utils/constants'

const getNotificationTabVisibilityAndType = () => {
  const featuresPermission = {
    hasEventManagement: hasModule('has_event_management'),
    hasFileSharingPermissions: window.serverContext.get('data_for_react')
      ?.matter_file_sharing_permissions,
    hasBudgetRequests: hasModule(MODULE.BUDGET_REQUESTS),
    hasMatterUpdateSharing: window.serverContext.get('data_for_react')
      ?.matter_updates_sharing_permissions
  }

  const isNotificationsTabVisible = Object.values(featuresPermission).some(Boolean)

  let notificationType
  if (featuresPermission.hasEventManagement) {
    notificationType = 'courtRules'
  } else if (featuresPermission.hasFileSharingPermissions) {
    notificationType = 'fileSharing'
  } else if (featuresPermission.hasMatterUpdateSharing) {
    notificationType = 'updates'
  } else if (featuresPermission.hasBudgetRequests) {
    notificationType = 'budgets'
  }

  return {
    isNotificationsTabVisible,
    notificationType
  }
}

const SettingsTab = ({ reviewerConfigId, readOnly, scopeId }) => {
  const { isNotificationsTabVisible, notificationType } = getNotificationTabVisibilityAndType()

  return (
    <VerticalTabs activeTabKey="allocations">
      <VerticalTab key="allocations" label="Allocations">
        <AllocationsContainer scopeId={scopeId} readOnly={readOnly} />
      </VerticalTab>
      <VerticalTab key="invoice" label="Reviewers">
        <Reviewers reviewerConfigId={reviewerConfigId} readOnly={readOnly} />
      </VerticalTab>
      <VerticalTab key="invoiceNotes" label="Invoice Notes">
        <Notes scopeId={scopeId} readOnly={readOnly} />
      </VerticalTab>
      {hasModule('has_perms') ? (
        <VerticalTab key="permissions" label="Permissions">
          <Permissions scopeId={scopeId} readOnly={readOnly} />
        </VerticalTab>
      ) : null}
      {isNotificationsTabVisible ? (
        <VerticalTab key="notifications" label="Notifications">
          <Notifications
            scope={SCOPE.MATTER}
            scopeId={scopeId}
            readOnly={readOnly}
            type={notificationType}
          />
        </VerticalTab>
      ) : null}
    </VerticalTabs>
  )
}

export default SettingsTab
